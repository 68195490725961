@font-face {
  font-family: "pixelmix";
  src: local("pixelmix"),
    url("./fonts/pixelmix.ttf") format("truetype");
  font-weight: normal;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Comic Sans MS'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #FFFFFF;
  font-family: 'pixelmix';

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  /* background-image: url('./images/Gold#3.png'); */
  /* background-color: whitesmoke; */
  background-image: url('./images/dadsGrid.png');
  background-repeat: repeat;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  min-height: 100vh;
}

.main-container-how-to {
  /* background-image: url('./images/Gold#3.png'); */
  /* background-image: linear-gradient(to bottom right, rgb(69, 34, 181), rgb(166, 145, 236));  display: flex; */
  background-image: url('./images/dadsGrid.png');
  background-repeat: repeat;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  min-height: 100vh;
  max-width: 100vw;
}

.top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  color: '#272626';
}

.first-container {
  /* min-height: 100vh; */
}

.wallet-info {
  color: #303030;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 10px;
}

.wallet-info-container {
  display: flex;
  flex-direction: row;
  background: whitesmoke;
  border: black 2px solid;
  border-radius: 10px;
  width: 100%;
}


.wallet-info-how-to {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.emoji {
  font-size: 1.5vw;
}

.menu-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background: whitesmoke;
  padding: 10px;
  border: black 2px solid;
  border-radius: 10px;
}

.menu-item {
  padding-right: 10px;
  padding-left: 10px;
}

.menu-item-text {
  color: #303030;
  font-weight: bold;
  font-size: large;
  text-decoration: none;
}

.menu-item-text-how-to {
  color: whitesmoke;
  font-weight: bold;
  font-size: large;
  text-decoration: none;
}

.menu-item-text:hover {
  color: rgb(50, 214, 214);
}

.main-section {
  background-image: linear-gradient(to bottom right, rgb(69, 34, 181), rgb(166, 145, 236));
  /* background-color: rgb(69, 34, 181); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 30px;
  border: black 10px solid;
  min-height: 80vh;
}

.main-section-team {
  /* background-image: linear-gradient(to top left, rgb(215, 255, 255), rgb(130, 255, 255)); */
  background-image: linear-gradient(to bottom right,  rgb(166, 145, 236), rgb(69, 34, 181));
  /* color: rgb(69, 34, 181); */
  color: white;
  /* background-color: rgb(69, 34, 181); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 30px;
  border: black 10px solid;
  min-height: 80vh;
}


/* Only for non-mobile view */
@media (min-width: 1030px) {
  .about-item-logo h1 {
    color: white;
    font-size: 3vw;
    padding-right: 10%;
    padding-left: 10%;
  }
  .how-to-item-logo h1 {
    color: white;
    font-size: 3vw;
    padding-right: 20%;
    padding-left: 20%;
  }

  .how-to-item {
    max-height: 80vh;
  }

  .gif {
    height: 60vh;
    border-color: black;
    border-width: 10px;
    border-radius: 10%;
    background-color: black;
    /* padding: 10px; */
  }

}
@media (max-width: 320px) {
  .menu-items {
    min-width: 90vw;
    justify-content: space-evenly;
    margin-top: 2%;
    flex-wrap: wrap-reverse;
  }
}
/* Only for Mobile View */
@media (max-width: 1030px) {
  .main-container{
    align-items: center;

  }


/* .main-container-how-to {

  justify-content: center;
  flex-direction: column;
  align-items: center;
} */
  .top {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
  }
  .wallet-info-container {
    justify-content: center;
    align-items: center;
  }
  .wallet-info {
  }

  .menu-items {
    min-width: 90vw;
    justify-content: space-evenly;
    margin-top: 2%;
  }


  .main-section {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    min-width: 90vw;
    justify-content: center;
    align-items: center;
  }
  .main-section-team {
    min-width: 90vw;
    justify-content: center;
    align-items: center;
  }

  .images{
    display: flex;
    min-width: 90vw;
    height: 100%;
  }

  .images-team{
    display: flex;
    min-width: 90vw;
    height: 100%;
  }

  .gif {
    max-height: 38vh;
  }

  .logo {
    min-width: 60vw;
  }

  .logo img {
    min-width: 70vw;

  }

  .minting-section {
  }

  .minting-deets {
    width: 70vw;
    /* padding-top: 10%; */
    margin-bottom: 20%;
  }

  .mint-button-container {
    padding-top: 10%;
    padding-bottom: 20%;
    margin-bottom: 10%;
  }

  .mint-date-text {
    text-align: center;
  }

  .how-to-item {
    min-width: 90vw;
    margin-top: 5%;
  }

  .how-to-item-info {
    font-size: 1.8vh;
  }
  .how-to-item-info h1{
    /* margin-left: 20%;
    margin-right: 20%; */
  }
  .about-item-info {
    font-size: 1.8vh;
  }
  .about-item-info h1{
  }
  .about-item-logo {
    min-width: 90vw;
    padding-top: 5%;

  }
  .about-item-logo h1 {
    font-size: xx-large;
  }
  .how-to-item-logo {
    min-width: 90vw;
    padding-top: 5%;
  }

  .how-to-top {
    width: 80vw;
  }
  .how-to-top img {
    min-width: 50vw
  }

  .about-top img {
    min-width: 50vw
  }

}

.main-section-team h2{
  color: black
}

.images {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap-reverse;
  width: 100%;
}

.images-team {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}


.logo {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

}

.logo img {
  width: 20vw;
}

.profile {
  border-radius: 10%;
  border-color: black;
  border-width: 10px;
  background-color: black;
  padding: 10px
}

.team-section {
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  width: 100%;
  /* height: 30% */
}

.team-deets {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 3vh;
  color: rgb(50, 214, 214);

}
.team-deets h2 {
  font-weight: bolder;
  color: white

}


.team-name {
  font-weight: bolder;
  font-size: 7vh;
  color: white;
  padding: 16px;
}



.twitter-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  color: white
}

.twitter-line a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.twitter-line:hover {
  color: #1DA1F2;
}

/* .twitter-line a:hover {
  color: #1DA1F2
} */


.twitter-item {
  padding: 5px;
}

.image-container {
  /* height: 60vh; */
  /* border-color: black;
  border-width: 10px;
  border-radius: 10%;
  background-color: black;
  padding: 10px;
  margin-top: 50px;
  margin-bottom: 50px; */
}


.gif {
  height: 60vh;
  border-color: black;
  border-width: 10px;
  border-radius: 10%;
  background-color: black;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.gif img {
  max-height: 100vh;
  max-width: 100vw;
}


.minting-section {
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* text-align: center; */
  width: 100%;
  height: 40%
}

.minting-deets {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-size: 3vh;
  /* background-color: green ; */
}

.minting-item {
  padding: 5px
}

.mint-button {
  /* background-color: green;
  color: red */
}

.mint-container {
  /* background-color: green;
  width: 5000px */
}

.how-to-container {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 30px;
  max-width: 100vw
}

.how-to-container h1 {
  color: rgb(69, 34, 181)
}

.how-to-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.how-to-item-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 80vh;
  border: 10px black solid;
  border-radius: 10%;
  width: 20vw;
  color: black;
  background-image: linear-gradient(to bottom right, rgb(130, 255, 255),teal);
}


.how-to-item-logo h1 {
  color: white;
  font-size: xx-large;
  padding-right: 20%;
  padding-left: 20%;


}

.how-to-top img {
  width: 10vw
}



.how-to-logo img {
  /* width: 5vw; */

}

.how-to-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.how-to-item {
  min-height: 80vh;
  border: 10px black solid;
  border-radius: 10%;
  width: 30vw;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: whitesmoke;

  /* background-color: green; */
}

.how-to-item-info {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  min-width: 90%;
  font-size: 1.4vh;
  margin-top: 4%;
  margin-left: 4%;
  margin-right: 4%;
  height: 50%
}

.how-to-link {
  text-decoration: none;
  font-weight: bold;
  color: rgb(50, 214, 214);

}


.how-to-item-info h1 {
  text-align: center;
  align-self: center;
}
.how-to-item-info text {
  padding: 5px;
}

.about-item-info {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: flex-start;
  text-align: left;
  font-size: 1.4vh;
  margin: 8%;
  height: 50%
}

.about-item-info h1 {
  text-align: right;
  align-self: flex-end;
}

.about-item-logo {
  height: 80vh;
  border: 10px black solid;
  border-radius: 10%;
  width: 20vw;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(to top left, rgb(130, 255, 255),teal);

  /* background-image: linear-gradient(to bottom right, rgb(69, 34, 181), rgb(166, 145, 236)); */
}


.about-item-logo h1 {
  color: white;
  font-size: xx-large;
  padding-right: 10%;
  padding-left: 10%;
}

.about-top img {
  width: 10vw
}

.about-item {
  height: 80vh;
  border: 10px black solid;
  border-radius: 10%;
  width: 30vw;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  background-color: whitesmoke;

  /* background-color: green; */
}

.about-item-info {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: flex-start;
  text-align: left;
  margin: 8%;
  height: 50%
}
